import React from 'react';
import FAQComponent from '../components/FAQComponent'; // Correct path to FAQComponent
import MobileNavbar from '../components/MobileNavbar'; // Import your mobile navbar component
import Navbar from '../components/Navbar'; // Import your desktop navbar component
import './SupportPage.css';
import useWindowSize from '../hooks/useWindowSize'; // Hook to get window width (if available)

const SupportPage = () => {
    const { width } = useWindowSize(); // Get the width of the window

    return (
        <div className="support-page">
            {width <= 768 ? <MobileNavbar /> : <Navbar />} {/* Conditional rendering of navbar */}

            <header className="support-header">
                <h1>Support Center</h1>
                <p>We're here to help. Browse our frequently asked questions or contact us for further assistance.</p>
            </header>
            <div className="faq-card">
                <FAQComponent />
            </div>
            <div className="contact-section">
                <h2>Need more help?</h2>
                <div className="contact-options">
                    <div className="contact-card">
                        <h3>Call Us</h3>
                        <p>+1 (424) 216-9253</p>
                    </div>
                    <div className="contact-card">
                        <h3>Email Us</h3>
                        <p>support@cleandata.me</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SupportPage;
