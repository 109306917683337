import React, { useState, useEffect } from 'react';
import './StatusBarComponent.css'; // Import the CSS file for styling

const StatusBarComponent = ({ apiUrl }) => {
    const [stats, setStats] = useState({
        progress: 0,
        sitesScanned: 0,
        lastScanTime: '',
        nextScanTime: ''
    });

    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch(apiUrl, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const data = await response.json();
                if (response.ok) {
                    // Format the date/time to be more human-readable
                    const formatDate = (isoString) => {
                        const date = new Date(isoString);
                        return date.toLocaleString('en-US', {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                            hour12: false
                        });
                    };

                    setStats({
                        progress: data.progressBar || 0, // Correct key for progress
                        sitesScanned: data.sitesScanned || 0,
                        lastScanTime: data.lastScanTime ? formatDate(data.lastScanTime) : '',
                        nextScanTime: data.nextScanTime ? formatDate(data.nextScanTime) : ''
                    });
                } else {
                    console.error('Error fetching status bar data:', data);
                }
            } catch (error) {
                console.error('Error fetching status bar data:', error);
            }
        };

        fetchData();
    }, [apiUrl]);

    return (
        <div className="status-bar-container">
            <h2 className="status-bar-title">Removal Progress</h2>
            <div className="progress-bar-container">
                <div className="progress-bar" style={{ width: `${stats.progress}%` }}></div>
                <div className="progress-bar-background"></div>
            </div>
            <div className="status-details">
                <div className="status-text">
                    <p><strong>Progress:</strong> {stats.progress}%</p>
                    <p><strong>Requests Sent:</strong> {stats.sitesScanned}</p>
                </div>
                <div className="scan-times">
                    <p><strong>Last Scan:</strong> {stats.lastScanTime}</p>
                    <p><strong>Next Scan:</strong> {stats.nextScanTime}</p>
                </div>
            </div>
        </div>
    );
};

export default StatusBarComponent;
