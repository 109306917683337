import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const SuccessPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSession = async () => {
      const searchParams = new URLSearchParams(location.search);
      const sessionId = searchParams.get('session_id');

      if (sessionId) {
        try {
          console.log('Fetching session with ID:', sessionId);
          const response = await fetch(`https://cleandata.fly.dev/api/session/${sessionId}`);

          if (!response.ok) {
            const text = await response.text();
            console.error('Error response:', text);
            throw new Error(`Error: ${response.status}, ${text}`);
          }

          const session = await response.json();
          console.log('Session data received:', session);

          if (session && session.token) {
            console.log('Token received:', session.token);
            localStorage.setItem('token', session.token); // Save the token for authenticated requests

            // Verify token is stored
            const storedToken = localStorage.getItem('token');
            if (storedToken) {
              console.log('Token successfully stored:', storedToken);

              // Redirect to the edit-info page with user details after a brief delay
              setTimeout(() => {
                navigate(`/edit-info?email=${encodeURIComponent(session.email)}&name=${encodeURIComponent(session.name)}`);
              }, 2000); // Delay of 2 seconds for user confirmation
            } else {
              console.error('Token storage failed');
            }
          }
        } catch (error) {
          console.error('Error fetching session:', error);
          setError(error.message);
        }
      }
      setLoading(false);
    };

    fetchSession();
  }, [navigate, location.search]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>Payment Successful!</h1>
      {error ? (
        <p>Error: {error}</p>
      ) : (
        <p>Thank you for your purchase. You will be redirected to the edit info page shortly.</p>
      )}
    </div>
  );
};

export default SuccessPage;
