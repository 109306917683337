import React, { useState, useEffect } from 'react';
import './DashboardStats.css'; // Import the CSS file for styling

const DashboardStats = ({ apiUrl }) => {
    const [stats, setStats] = useState({
        dataBrokerExposure: 0,
        sitesScanned: 0,
        profilesFound: 0
    });

    useEffect(() => {
        const fetchStats = async () => {
            const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage
            try {
                const response = await fetch(apiUrl, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const data = await response.json();
                if (response.ok) {
                    setStats({
                        dataBrokerExposure: data.dataBrokerExposure || 0,
                        sitesScanned: data.sitesScanned || 0,
                        profilesFound: data.profilesFound || 0
                    });
                } else {
                    console.error('Error fetching stats:', data);
                }
            } catch (error) {
                console.error('Error fetching stats:', error);
            }
        };

        fetchStats();
    }, [apiUrl]);

    return (
        <div className="dashboard-stats-container">
            <div className="dashboard-stat-box">
                <div className="stat-header">Data Broker Exposure</div>
                <div className="stat-number">{stats.dataBrokerExposure}</div>
            </div>
            <div className="dashboard-stat-box">
                <div className="stat-header">Sites Scanned</div>
                <div className="stat-number">{stats.sitesScanned}</div>
            </div>
            <div className="dashboard-stat-box">
                <div className="stat-header">Profiles Found</div>
                <div className="stat-number">{stats.profilesFound}</div>
            </div>
        </div>
    );
};

export default DashboardStats;
