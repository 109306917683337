import React, { useState, useEffect } from 'react';
import { FaSpinner, FaCircle } from 'react-icons/fa'; // Import live recording icon
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import './DataRemovalsPage.css';
import NavigationTabs from '../components/NavigationTabs';
import DataPointsComponent from '../components/DataPointsComponent';
import DataBrokerListComponent from '../components/DataBrokerListComponent';
import Sidebar from '../components/Sidebar';

const auth = '71837-cleandata';

const DataRemovalsPage = () => {
  const [loadingStates, setLoadingStates] = useState([]);
  const [userName, setUserName] = useState({ firstName: 'John', lastName: 'Wick' });
  const [urls, setUrls] = useState([]);
  const [fullImageIndex, setFullImageIndex] = useState(-1);

  // Fetch user data
  useEffect(() => {
    const fetchUserName = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found, cannot fetch user data');
        return;
      }

      try {
        const response = await fetch('https://cleandata.fly.dev/api/user-data', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          console.error('Failed to fetch user data');
          throw new Error('Failed to fetch user data');
        }

        const user = await response.json();
        setUserName({
          firstName: user.first_name || 'John',
          lastName: user.last_name || 'Wick',
        });
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserName();
  }, []);

  // Update URLs based on userName
  useEffect(() => {
    const updatedUrls = [
      {
        url: `https://dataveria.com/profile/search?fname=${userName.firstName}&lname=${userName.lastName}`,
        siteName: 'Dataveria',
      },
      {
        url: `https://411.info/people?fn=${userName.firstName}&ln=${userName.lastName}`,
        siteName: '411.info',
      },
      {
        url: `https://www.anywho.com/people/${userName.firstName}%20+${userName.lastName}/`,
        siteName: 'AnyWho',
      },
      {
        url: `https://www.addresses.com/people/${userName.firstName}+${userName.lastName}/`,
        siteName: 'Addresses.com',
      },
      {
        url: `https://arrestfacts.com/ng/search?fname=${userName.firstName}&lname=${userName.lastName}&state=&city=`,
        siteName: 'ArrestFacts',
      },
      {
        url: `https://clubset.com/profile/search?fname=${userName.firstName}&lname=${userName.lastName}&state=&city=&fage=None`,
        siteName: 'Clubset',
      },
      {
        url: `https://clustrmaps.com/persons/${userName.firstName}-${userName.lastName}`,
        siteName: 'ClustrMaps',
      },
      {
        url: `https://www.corporationwiki.com/search/results?term=${userName.firstName}%20${userName.lastName}`,
        siteName: 'Corporation Wiki',
      },
      {
        url: `https://fastpeoplesearch.io/search?first_name=${userName.firstName}&last_name=${userName.lastName}&state=`,
        siteName: 'FastPeopleSearch.io',
      },
      {
        url: `https://freepeopledirectory.com/name/${userName.firstName}-${userName.lastName}/`,
        siteName: 'FreePeopleDirectory',
      },
      {
        url: `https://neighbor.report/${userName.firstName}-${userName.lastName}`,
        siteName: 'Neighbor Report',
      },
      {
        url: `https://www.peekyou.com/${userName.firstName}_${userName.lastName}`,
        siteName: 'PeekYou',
      },
    ];

    setUrls(updatedUrls);
    setLoadingStates(updatedUrls.map(() => true));
  }, [userName]);

  const handleImageLoad = (index) => {
    setLoadingStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = false;
      return newStates;
    });
  };

  return (
    <div className="data-removals-wrapper">
      <Sidebar />
      <div className="data-removals-container">
        <div className="header-container">
          <h1 className="header">{`${userName.firstName} ${userName.lastName}'s Privacy Reports`}</h1>
          <nav className="breadcrumb">Home / Data Removals</nav>
        </div>
        <NavigationTabs />
        <DataPointsComponent />

        <div className="data-points-section">
          <h2>Your Data Removal Screenshots</h2>
          <div className="removal-images-container">
            {urls.map((item, index) => (
              <div
                key={index}
                className="image-preview"
                onClick={() => setFullImageIndex(index)}
              >
                {loadingStates[index] && (
                  <FaSpinner className="loading-spinner" />
                )}
                <img
                  src={`https://image.thum.io/get/auth/${auth}/noanimate/fullpage/maxAge/24/width/300/?url=${encodeURIComponent(
                    item.url
                  )}`}
                  alt={`Preview ${index + 1}`}
                  onLoad={() => handleImageLoad(index)}
                  style={{ display: loadingStates[index] ? 'none' : 'block' }}
                />
                {/* Overlay with site name and live recording icon */}
                <div className="image-overlay">
                  <FaCircle className="live-icon" />
                  <span className="site-name">{item.siteName}</span>
                </div>
              </div>
            ))}
          </div>
        </div>

        {fullImageIndex >= 0 && urls[fullImageIndex] && (
          <Lightbox
            mainSrc={`https://image.thum.io/get/auth/${auth}/noanimate/fullpage/maxAge/24/?url=${encodeURIComponent(
              urls[fullImageIndex].url
            )}`}
            onCloseRequest={() => setFullImageIndex(-1)}
            imageTitle={`${urls[fullImageIndex].siteName}`}
            enableZoom={true}
            reactModalStyle={{ overlay: { zIndex: 1000 } }}
          />
        )}

        <DataBrokerListComponent />
      </div>
    </div>
  );
};

export default DataRemovalsPage;
