import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTachometerAlt, faUserEdit, faExchangeAlt, faListAlt, faLifeRing, faPlus } from '@fortawesome/free-solid-svg-icons';
import './Sidebar.css';
import profileImage from '../assets/profile.jpg'; // Ensure this path is correct

const Sidebar = () => {
  const [userName, setUserName] = useState({ firstName: '', lastName: '' });

  useEffect(() => {
    const fetchUserName = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found, cannot fetch user data');
        return;
      }

      try {
        const response = await fetch('https://cleandata.fly.dev/api/user-data', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          console.error('Failed to fetch user data, status:', response.status);
          console.error('Response text:', await response.text());
          throw new Error('Failed to fetch user data');
        }

        const user = await response.json();
        setUserName({
          firstName: user.first_name || '',
          lastName: user.last_name || '',
        });
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserName();
  }, []);

  return (
    <div className="sidebar">
      <div className="sidebar-profile">
        <img src={profileImage} alt="User" className="profile-pic" />
        <div className="profile-info">
          <h3>{`${userName.firstName} ${userName.lastName}`}</h3>
          <p>1 Year Plan</p>
        </div>
      </div>
      <nav className="sidebar-nav">
        <a href="/dashboard" className="nav-item">
          <FontAwesomeIcon icon={faTachometerAlt} className="nav-icon" /> Dashboard
        </a>
        <a href="/edit-info" className="nav-item">
          <FontAwesomeIcon icon={faUserEdit} className="nav-icon" /> Edit your info
        </a>
        <a href="/change-plan" className="nav-item">
          <FontAwesomeIcon icon={faExchangeAlt} className="nav-icon" /> Change plan
        </a>
        <a href="/sites" className="nav-item">
          <FontAwesomeIcon icon={faListAlt} className="nav-icon" /> Sites we cover
        </a>
        <a href="/support" className="nav-item">
          <FontAwesomeIcon icon={faLifeRing} className="nav-icon" /> Support
        </a>
      </nav>
      <button className="add-family-member-button">
        <FontAwesomeIcon icon={faPlus} className="nav-icon" /> Add a family member
      </button>
    </div>
  );
};

export default Sidebar;
