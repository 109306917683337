import React, { useEffect, useState, useContext } from 'react';
import { FaSpinner } from 'react-icons/fa';  // Import the loading spinner icon
import './Dashboard.css'; // Import the CSS file for styling
import StatusBarComponent from '../components/StatusBarComponent';
import DataBrokerListComponent from '../components/DataBrokerListComponent';
import Sidebar from '../components/Sidebar';
import DashboardStats from '../components/DashboardStats';
import FeatureToggles from '../components/FeatureToggles';
import DashboardHeader from '../components/DashboardHeader'; // Import the new DashboardHeader component
import DataPointsComponent from '../components/DataPointsComponent';
import NavigationTabs from '../components/NavigationTabs';
import { AuthContext } from '../context/AuthContext'; // Import AuthContext

const Dashboard = () => {
    const [currentTask, setCurrentTask] = useState('');
    const [dataBrokerList, setDataBrokerList] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true); // Add loading state

    const { user, loading: authLoading, error: authError, getToken } = useContext(AuthContext); // Use AuthContext

    useEffect(() => {
        const fetchData = async () => {
            if (authLoading) return; // Wait until auth is fully loaded
            if (authError) {
                setError('Authentication error');
                setLoading(false);
                return;
            }

            const token = getToken();
            if (!token) {
                console.error('No token found');
                setError('No token found');
                setLoading(false);
                return;
            }

            try {
                console.log('Fetching dashboard data...');
                const response = await fetch('https://dashboard-api-server.fly.dev/api/stats', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    const text = await response.text();
                    console.error('Error response:', text);
                    throw new Error(`HTTP error! status: ${response.status}, ${text}`);
                }

                const data = await response.json();
                setCurrentTask(data.currentTask);
                setDataBrokerList(data.dataBrokerList);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching dashboard data:', error);
                setError('Failed to fetch dashboard data');
                setLoading(false);
            }
        };

        fetchData();
    }, [authLoading, authError, getToken]); // Re-fetch when auth state changes

    if (loading) {
        return (
            <div className="loading-container">
                <FaSpinner className="loading-spinner" />
            </div>
        ); // Display loading spinner while fetching data
    }

    return (
        <div className="dashboard-wrapper">
            <Sidebar />
            <div className="dashboard-container">
                <DashboardHeader currentTask={currentTask} />
                <main className="dashboard-main">
                    <NavigationTabs />
                    <StatusBarComponent apiUrl="https://dashboard-api-server.fly.dev/api/stats" />
                    <DashboardStats apiUrl="https://dashboard-api-server.fly.dev/api/stats" />
                    <DataPointsComponent />
                    <DataBrokerListComponent dataBrokerList={dataBrokerList} />
                    <FeatureToggles apiUrl="https://dashboard-api-server.fly.dev/api/feature-toggles" />
                </main>
            </div>
            {error && <div className="error-message">{error}</div>}
        </div>
    );
};

export default Dashboard;
