import React, { useEffect, useState } from 'react';
import './DataBrokerListComponent.css'; // Import the CSS file for styling
import { FaVideo } from 'react-icons/fa';

const DataBrokerListComponent = ({ liveViewUrl }) => {
    const [dataBrokers, setDataBrokers] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true); // Added loading state

    useEffect(() => {
        const fetchDataBrokers = async () => {
            const token = localStorage.getItem('token');

            // Mock data to use when not authenticated
            const mockData = [
                {
                    name: 'Anywho',
                    removalTime: '5 days',
                    dataBrokerReport: '#',
                },
                {
                    name: 'ClustrMaps',
                    removalTime: '2 days',
                    dataBrokerReport: '#',
                },
                {
                    name: 'PeekYou',
                    removalTime: '1 day',
                    dataBrokerReport: '#',
                },
                {
                    name: 'Addresses.com',
                    removalTime: '7 days',
                    dataBrokerReport: '#',
                },
                {
                    name: '411.info',
                    removalTime: '3 days',
                    dataBrokerReport: '#',
                },
            ];

            if (!token) {
                console.warn('No token found, using mock data.');
                setDataBrokers(mockData);
                setIsLoading(false); // Set loading to false after setting data
                return;
            }

            try {
                const response = await fetch('https://dashboard-api-server.fly.dev/api/stats', {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                if (!response.ok) {
                    if (response.status === 403) {
                        console.warn('Received 403 Forbidden, using mock data.');
                        setDataBrokers(mockData);
                    } else {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                } else {
                    const data = await response.json();
                    setDataBrokers(data.dataBrokerList);
                }
            } catch (error) {
                console.error('Error fetching data brokers:', error);
                setError(error.message);
                // Optionally use mock data even on fetch errors
                console.warn('Using mock data due to fetch error.');
                setDataBrokers(mockData);
            } finally {
                setIsLoading(false); // Set loading to false after fetch completes
            }
        };

        fetchDataBrokers();
    }, []);

    const calculateStatus = (removalTime) => {
        const [value, unit] = removalTime.split(' ');
        const days = unit.includes('day') ? parseInt(value) : 0;
        const removalDate = new Date(Date.now() + days * 24 * 60 * 60 * 1000);
        return removalDate < new Date() ? 'Completed' : 'In Progress';
    };

    if (isLoading) {
        return <div className="loading-state">Loading data brokers...</div>;
    }

    if (error && dataBrokers.length === 0) {
        return <div>Error: {error}</div>;
    }

    // Limit the displayed brokers to 4 and add 1 live view at the top
    const displayedBrokers = dataBrokers.slice(0, 4);
    const remainingBrokers = dataBrokers.length - displayedBrokers.length;

    return (
        <div className="databrokerlist-container">
            <table className="databrokerlist-table">
                <thead>
                    <tr>
                        <th>Profile</th>
                        <th>Status</th>
                        <th>Removal time</th>
                        <th>Data broker report</th>
                    </tr>
                </thead>
                <tbody>
                    {/* Place the Live View at the top */}
                    {liveViewUrl && (
                        <tr>
                            <td data-label="Profile">
                                <span style={{ color: 'red', fontWeight: 'bold' }}>Live Data Removal <FaVideo /></span>
                            </td>
                            <td data-label="Status">Recording</td>
                            <td data-label="Removal time">Ongoing</td>
                            <td data-label="Data broker report">
                                <a href={liveViewUrl} target="_blank" rel="noopener noreferrer" style={{ color: 'white', fontWeight: 'bold' }}>
                                    View Live
                                </a>
                            </td>
                        </tr>
                    )}

                    {/* Render the 4 broker profiles */}
                    {displayedBrokers.map((broker, index) => (
                        <tr key={index}>
                            <td data-label="Profile">{broker.name}</td>
                            <td data-label="Status">{calculateStatus(broker.removalTime)}</td>
                            <td data-label="Removal time">{broker.removalTime}</td>
                            <td data-label="Data broker report">
                                <a href={broker.dataBrokerReport} target="_blank" rel="noopener noreferrer">
                                    View Report
                                </a>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="databrokerlist-cards">
                {/* Place the Live View at the top */}
                {liveViewUrl && (
                    <div className="databrokerlist-card">
                        <div className="databrokerlist-card-content">
                            <span className="databrokerlist-card-title" style={{ color: 'red', fontWeight: 'bold' }}>Live Data Removal</span>
                            <FaVideo style={{ marginLeft: '8px', color: 'red' }} />
                        </div>
                        <div className="databrokerlist-card-content">
                            <span className="databrokerlist-card-title">Status:</span>
                            <span className="databrokerlist-card-text">Recording</span>
                        </div>
                        <div className="databrokerlist-card-content">
                            <span className="databrokerlist-card-title">Removal time:</span>
                            <span className="databrokerlist-card-text">Ongoing</span>
                        </div>
                        <div className="databrokerlist-card-content">
                            <span className="databrokerlist-card-title">Data broker report:</span>
                            <a href={liveViewUrl} className="databrokerlist-link" style={{ color: 'white', fontWeight: 'bold' }}>
                                View Live
                            </a>
                        </div>
                    </div>
                )}

                {/* Render the 4 broker profiles */}
                {displayedBrokers.map((broker, index) => (
                    <div key={index} className="databrokerlist-card">
                        <div className="databrokerlist-card-content">
                            <span className="databrokerlist-card-title">Profile:</span>
                            <span className="databrokerlist-card-text">{broker.name}</span>
                        </div>
                        <div className="databrokerlist-card-content">
                            <span className="databrokerlist-card-title">Status:</span>
                            <span className="databrokerlist-card-text">{calculateStatus(broker.removalTime)}</span>
                        </div>
                        <div className="databrokerlist-card-content">
                            <span className="databrokerlist-card-title">Removal time:</span>
                            <span className="databrokerlist-card-text">{broker.removalTime}</span>
                        </div>
                        <div className="databrokerlist-card-content">
                            <span className="databrokerlist-card-title">Data broker report:</span>
                            <a href={broker.dataBrokerReport} className="databrokerlist-link">
                                View Report
                            </a>
                        </div>
                    </div>
                ))}
            </div>

            {/* Display the number of remaining brokers */}
            {remainingBrokers > 0 && (
                <div className="databrokerlist-remaining">
                    <strong>{remainingBrokers} Remaining...</strong>
                </div>
            )}
        </div>
    );
};

export default DataBrokerListComponent;
