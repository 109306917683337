import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { AuthContext } from '../context/AuthContext'; // Import context
import laptopImage from '../assets/laptop-image.png'; // Corrected path
import './LoginPage.css';

const LoginPage = () => {
    const { handleLogin } = useContext(AuthContext); // Access login handler
    const [email, setEmail] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [otp, setOtp] = useState('');
    const navigate = useNavigate();

    const handleSendOtp = async (e) => {
        e.preventDefault();
        console.log('Sending OTP to:', email);
        
        try {
            const response = await fetch('https://cleandata.fly.dev/send-otp', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email }),
            });

            if (response.ok) {
                console.log('OTP sent successfully to:', email);
                setOtpSent(true);
            } else {
                const errorText = await response.text();
                console.error('Error sending OTP:', errorText);
            }
        } catch (error) {
            console.error('Network error when sending OTP:', error.message);
        }
    };

    const handleVerifyOtp = async (e) => {
        e.preventDefault();
        console.log('Verifying OTP for:', email);

        try {
            const response = await fetch('https://cleandata.fly.dev/verify-otp', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, otp }),
            });

            if (response.ok) {
                const data = await response.json();
                localStorage.setItem('token', data.token);
                handleLogin(email, data.token); // Use context to log in
                console.log('OTP verified, login successful!');
                navigate('/dashboard'); // Redirect to dashboard
            } else {
                const errorText = await response.text();
                console.error('Error verifying OTP:', errorText);
            }
        } catch (error) {
            console.error('Network error when verifying OTP:', error.message);
        }
    };

    return (
        <div className="login-page">
            <div className="left-section">
                <div className="logo">
                    <h1>Cleandata<sup>™</sup></h1>
                </div>
                <div className="welcome-message">
                    <h2>Sign In To Get Protected</h2>
                </div>
                {!otpSent ? (
                    <form onSubmit={handleSendOtp} className="login-form">
                        <div className="input-group">
                            <input
                                type="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                className="input-field"
                            />
                        </div>
                        <button type="submit" className="login-button">
                            Login
                        </button>
                    </form>
                ) : (
                    <form onSubmit={handleVerifyOtp} className="login-form">
                        <div className="input-group">
                            <input
                                type="text"
                                placeholder="Enter OTP"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                                required
                                className="input-field"
                            />
                        </div>
                        <button type="submit" className="login-button">
                            Verify OTP
                        </button>
                    </form>
                )}
                <div className="login-footer">
                    <a href="/forgot-password" className="forgot-password">Forgot your password?</a>
                    <span>Not a subscriber yet? <a href="/signup" className="signup-link">Sign up</a></span>
                </div>
            </div>
            <div className="right-section">
                <img src={laptopImage} alt="Dashboard Preview" className="laptop-image" />
            </div>
        </div>
    );
};

export default LoginPage;
