import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './EditInfoPage.css';
import ProgressTimeline from '../components/ProgressTimeline';
import FAQ2Component from '../components/FAQ2Component';

const EditInfoPage = () => {
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(0);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        address: '',
        country: '',
        city: '',
        zipCode: '',
        additionalEmails: [''],
        additionalPhoneNumbers: [''],
    });
    const [cities, setCities] = useState([]);
    const [loadingCities, setLoadingCities] = useState(false);
    const [formStatus, setFormStatus] = useState('unsubmitted');

    useEffect(() => {
        const fetchUserData = async () => {
            const token = localStorage.getItem('token');
            if (!token) {
                navigate('/login');
                return;
            }

            try {
                const response = await fetch('https://cleandata.fly.dev/api/user-data', {
                    headers: { 'Authorization': `Bearer ${token}` }
                });

                if (!response.ok) throw new Error('Failed to fetch user data');

                const user = await response.json();
                setFormData((prevData) => ({
                    ...prevData,
                    email: user.email || '',
                    firstName: user.first_name || '',
                    lastName: user.last_name || '',
                }));
            } catch (error) {
                navigate('/login');
            }
        };

        fetchUserData();
    }, [navigate]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleCountryChange = async (e) => {
        const { value } = e.target;
        setFormData((prevData) => ({ ...prevData, country: value, city: '' }));
        setLoadingCities(true);

        try {
            const response = await fetch('https://countriesnow.space/api/v0.1/countries/cities', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ country: value }),
            });

            if (!response.ok) throw new Error('Error fetching cities');

            const result = await response.json();
            setCities(result.data);
        } catch (error) {
            setCities([]);
        } finally {
            setLoadingCities(false);
        }
    };

    const handleAddField = (field) => {
        setFormData((prevData) => ({
            ...prevData,
            [field]: [...prevData[field], ''],
        }));
    };

    const handleRemoveField = (field, index) => {
        setFormData((prevData) => {
            const updatedFields = [...prevData[field]];
            updatedFields.splice(index, 1);
            return { ...prevData, [field]: updatedFields };
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const token = localStorage.getItem('token');
            const response = await fetch('https://cleandata.fly.dev/api/submit-data', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) throw new Error('Form submission error');

            setFormStatus('submitted');
            navigate('/dashboard');
        } catch (error) {
            setFormStatus('error');
        }
    };

    return (
        <>
            <ProgressTimeline currentStep={currentStep} />
            <form onSubmit={handleSubmit} className="formStyle">
                {formStatus === 'submitted' && <div className="responseText">Form submitted successfully! Redirecting...</div>}
                {formStatus === 'error' && <div className="responseTextError">Something went wrong. Please try again!</div>}

                <div className="rowStyle">
                    <input type="text" name="firstName" placeholder="First Name" value={formData.firstName} onChange={handleChange} className="inputStyle" />
                    <input type="text" name="lastName" placeholder="Last Name" value={formData.lastName} onChange={handleChange} className="inputStyle" />
                </div>
                <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} className="inputStyle" />
                <input type="tel" name="phoneNumber" placeholder="Phone Number" value={formData.phoneNumber} onChange={handleChange} className="inputStyle" />
                <input type="text" name="address" placeholder="Address" value={formData.address} onChange={handleChange} className="inputStyle" />

                <select name="country" value={formData.country} onChange={handleCountryChange} className="inputStyle">
                    <option value="">Select Country...</option>
                    {countries.map((country, index) => (
                        <option key={index} value={country}>
                            {country}
                        </option>
                    ))}
                </select>

                <select name="city" value={formData.city} onChange={handleChange} className="inputStyle" disabled={loadingCities}>
                    <option value="">{loadingCities ? 'Loading...' : 'Select City/State...'}</option>
                    {cities.map((city, index) => (
                        <option key={index} value={city}>
                            {city}
                        </option>
                    ))}
                </select>

                <input type="text" name="zipCode" placeholder="Zip Code" value={formData.zipCode} onChange={handleChange} className="inputStyle" />

                {formData.additionalEmails.map((email, index) => (
                    <div key={index} className="rowStyle">
                        <input type="email" placeholder={`Additional Email ${index + 1}`} value={email} onChange={(e) => {
                            const newEmails = [...formData.additionalEmails];
                            newEmails[index] = e.target.value;
                            setFormData({ ...formData, additionalEmails: newEmails });
                        }} className="inputStyle" />
                        <button type="button" onClick={() => handleRemoveField('additionalEmails', index)} className="removeButtonStyle">Remove</button>
                    </div>
                ))}

                <button type="button" onClick={() => handleAddField('additionalEmails')} className="addButtonStyle">Add Email</button>

                {formData.additionalPhoneNumbers.map((phoneNumber, index) => (
                    <div key={index} className="rowStyle">
                        <input type="tel" placeholder={`Additional Phone Number ${index + 1}`} value={phoneNumber} onChange={(e) => {
                            const newPhoneNumbers = [...formData.additionalPhoneNumbers];
                            newPhoneNumbers[index] = e.target.value;
                            setFormData({ ...formData, additionalPhoneNumbers: newPhoneNumbers });
                        }} className="inputStyle" />
                        <button type="button" onClick={() => handleRemoveField('additionalPhoneNumbers', index)} className="removeButtonStyle">Remove</button>
                    </div>
                ))}

                <button type="button" onClick={() => handleAddField('additionalPhoneNumbers')} className="addButtonStyle">Add Phone Number</button>

                <button type="submit" className="submitButtonStyle">Submit</button>
            </form>
            <FAQ2Component />
        </>
    );
};

const countries = [
    'United States', 'Canada', 'United Kingdom', 'Australia', 'Germany', 'France',
    'Brazil', 'India', 'China', 'Japan', 'South Korea', 'Mexico', 'Italy', 'Spain',
    'Russia', 'South Africa', 'New Zealand', 'Singapore', 'Malaysia', 'Thailand',
    'Vietnam', 'Philippines', 'Indonesia', 'Saudi Arabia', 'United Arab Emirates',
    'Turkey', 'Egypt', 'Morocco', 'Kenya', 'Nigeria', 'Ghana'
];

export default EditInfoPage;
