import React, { createContext, useState, useEffect } from 'react';
import { getUser, login, logout } from '../services/authService';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUser = async () => {
            setLoading(true);
            setError(null);
            try {
                const userData = await getUser();
                if (userData) {
                    setUser(userData);
                }
            } catch (err) {
                console.error('Error fetching user data:', err);
                setError('Failed to load user data');
            } finally {
                setLoading(false);
            }
        };
        fetchUser();
    }, []);

    const handleLogin = async (email, otp) => {
        setLoading(true);
        setError(null);
        try {
            const userData = await login(email, otp);
            if (userData) {
                setUser(userData);
                localStorage.setItem('token', userData.token); // Save JWT token to local storage
            }
        } catch (err) {
            console.error('Error logging in:', err);
            setError('Failed to login');
        } finally {
            setLoading(false);
        }
    };

    const handleLogout = () => {
        logout();
        setUser(null);
        localStorage.removeItem('token'); // Clear token on logout
    };

    const getToken = () => localStorage.getItem('token'); // Method to retrieve token

    return (
        <AuthContext.Provider value={{ user, loading, error, handleLogin, handleLogout, getToken }}>
            {children}
        </AuthContext.Provider>
    );
};
