import React, { useState, useEffect, useRef } from 'react';
import './MobileNavbar.css'; // Import the CSS file
import { FaEraser, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const MobileNavbar = () => {
  const [isOpen, setIsOpen] = useState(false); // State for main menu
  const [isFreeToolsOpen, setIsFreeToolsOpen] = useState(false); // State for Free Tools submenu
  const navRef = useRef(); // Reference for detecting outside clicks

  // Toggle main menu
  const toggleMenu = () => {
    setIsOpen(!isOpen);
    if (isOpen) {
      setIsFreeToolsOpen(false); // Close Free Tools if main menu is closed
    }
  };

  // Toggle Free Tools submenu
  const toggleFreeTools = () => {
    setIsFreeToolsOpen(!isFreeToolsOpen);
  };

  // Close menus when a link is clicked
  const closeMenus = () => {
    setIsOpen(false);
    setIsFreeToolsOpen(false);
  };

  // Close menus when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setIsOpen(false);
        setIsFreeToolsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Prevent body scroll when menu is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  return (
    <nav className="mobile-navbar" ref={navRef}>
      <div className="navbar-header">
        <div className="navbar-brand">
          <FaEraser size={30} color="#D8FF60" className="eraser-icon" />
          <span className="navbar-title">CleanData</span>
        </div>
        <button
          className="navbar-toggle"
          aria-label="Toggle navigation"
          aria-expanded={isOpen}
          onClick={toggleMenu}
        >
          <div className={`hamburger-icon ${isOpen ? 'open' : ''}`}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>
      </div>
      {/* Main Menu Drawer */}
      <div className={`navbar-drawer ${isOpen ? 'open' : ''}`}>
        <Link to="/" onClick={closeMenus}>Home</Link>
        <Link to="/login" onClick={closeMenus}>Login</Link>
        <Link to="/signup" onClick={closeMenus}>Signup</Link>
        {/* Free Tools Dropdown */}
        <div className="dropdown">
          <button
            className={`dropdown-toggle ${isFreeToolsOpen ? 'open' : ''}`}
            onClick={toggleFreeTools}
            aria-haspopup="true"
            aria-expanded={isFreeToolsOpen}
          >
            Free Tools
            {isFreeToolsOpen ? <FaChevronUp className="chevron-icon" /> : <FaChevronDown className="chevron-icon" />}
          </button>
          <div className={`dropdown-menu ${isFreeToolsOpen ? 'open' : ''}`}>
            <Link to="/password-check" onClick={closeMenus}>Password Checker</Link>
            <Link to="/file-scan" onClick={closeMenus}>Virus Scanner</Link>
            <Link to="/data-broker-check" onClick={closeMenus}>Data Broker Check</Link>
            <Link to="/data-leak" onClick={closeMenus}>Check Your Email</Link>
          </div>
        </div>
        <Link to="/support" onClick={closeMenus}>Support</Link>
      </div>
    </nav>
  );
};

export default MobileNavbar;
