import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './ScanningPage.css';
import peopleSearchSites from './peopleSearchSites';
import { fetchMatchProbability } from '../fetchMatchProbability';
import { FaExclamationTriangle, FaInfoCircle } from 'react-icons/fa';
import MobileNavBar from '../components/MobileNavbar';

const ScanningPage = () => {
    const [scannedCount, setScannedCount] = useState(0);
    const [matchCount, setMatchCount] = useState(0);
    const [matchProbability, setMatchProbability] = useState(null);
    const [matches, setMatches] = useState([]); // Track all matches
    const [visibleMatches, setVisibleMatches] = useState([]); // Track visible matches
    const [currentSite, setCurrentSite] = useState(''); // Track current site being scanned
    const [scanningComplete, setScanningComplete] = useState(false); // Track if scanning is complete
    const navigate = useNavigate();
    const location = useLocation();

    const { firstName, lastName, city, country } = location.state || {};

    // Check for missing details and redirect if necessary
    useEffect(() => {
        if (!firstName || !lastName || !city || !country) {
            console.log("Missing user details, redirecting to home.");
            navigate('/');
            return;
        }

        // Initialize match probability once
        const initializeMatchProbability = async () => {
            try {
                const probability = await fetchMatchProbability(firstName, lastName, city, country);
                setMatchProbability(probability);
                console.log('Fetched match probability:', probability);
            } catch (error) {
                console.error('Error fetching match probability:', error);
                setMatchProbability(0.1); // Fallback value in case of error
            }
        };

        initializeMatchProbability();
    }, [navigate, firstName, lastName, city, country]);

    // Scanning logic and matching
    useEffect(() => {
        const totalSites = peopleSearchSites.length;

        const scanSites = async (index) => {
            if (index >= totalSites) {
                setScanningComplete(true); // Mark scanning as complete if all sites are processed
                return;
            }

            const site = peopleSearchSites[index];
            setCurrentSite(site); // Update the current site being scanned

            // Simulate varying scan times
            const scanTime = Math.random() * (800 - 200) + 200;

            setTimeout(() => {
                const guaranteedThreats = Math.max(5, Math.ceil(matchProbability * totalSites));

                if (Math.random() < matchProbability && matchCount < guaranteedThreats) {
                    const match = { site, id: index };
                    setMatchCount((prevCount) => prevCount + 1);
                    setMatches((prevMatches) => [...prevMatches, match]);

                    setVisibleMatches((prevVisibleMatches) => [...prevVisibleMatches, match]);

                    // Remove the match card after some time
                    setTimeout(() => {
                        setVisibleMatches((prevVisibleMatches) =>
                            prevVisibleMatches.filter((m) => m.id !== match.id)
                        );
                        console.log(`Removing match from view: ${match.site}`);
                    }, 3000); // 3-second display time
                }

                // Update progress bar
                const progressBar = document.querySelector('.progress');
                if (progressBar) {
                    progressBar.style.width = `${((index + 1) / totalSites) * 100}%`;
                }

                setScannedCount(index + 1); // Increment scanned count
                scanSites(index + 1); // Recursively scan the next site
            }, scanTime);
        };

        if (matchProbability !== null) {
            scanSites(0); // Start scanning from the first site
        }
    }, [matchProbability]); // Only depend on matchProbability

    // Ensure scanning is complete before handling any further actions
    useEffect(() => {
        if (scanningComplete) {
            console.log('Scanning complete, preparing redirection...');

            // Scroll to the top and then redirect
            setTimeout(() => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                setTimeout(() => {
                    navigate('/signup', { state: { firstName, lastName, city, country, matchCount } });
                }, 1000); // Add a slight delay to allow smooth scrolling before redirect
            }, 500); // Delay before starting scroll to the top
        }
    }, [scanningComplete, navigate, firstName, lastName, city, country, matchCount]);

    return (
        <div className="scanning-page">
            {/* Mobile Navigation Bar */}
            <MobileNavBar />

            <div className="scanning-header">
                <h1>Scanning Data Brokers for: {firstName} {lastName}</h1>
                <p>We’re searching for your personal information on people-search sites.</p>
            </div>
            <div className="scanning-status">
                <p className="scanning-count">{Math.min(scannedCount, peopleSearchSites.length)} out of {peopleSearchSites.length} websites scanned</p>
                <p>Currently scanning: <span className="current-site">{currentSite}</span></p>
                <div className="progress-bar">
                    <div className="progress" style={{ width: '0%' }}></div>
                </div>
                <div className="match-counter">
                    <p>Possible threats found: {matchCount}</p>
                </div>
                <div className="match-list">
                    {visibleMatches.map((match) => (
                        <div key={`${match.site}-${match.id}`} id={`match-${match.id}`} className="match-card">
                            <FaExclamationTriangle className="icon" />
                            <p>Possible Match Found: {match.site}</p>
                        </div>
                    ))}
                </div>
            </div>

            {/* Info Box */}
            <div className="info-box">
                <FaInfoCircle className="info-icon" />
                <p>
                    This is a quick free scan. With limited information, we cannot fully locate or remove all your personal data from the internet.
                    To take full control of your data, sign up for a <strong>free trial</strong> and get your data removed.
                </p>
            </div>
        </div>
    );
};

export default ScanningPage;
