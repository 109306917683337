export const fetchMatchProbability = async (firstName, lastName, city, country) => {
    const apiKey = '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'; // Replace with your actual API key
    const location = `${city}, ${country}`.trim();

    console.log(`Fetching match probability for: ${firstName} ${lastName}, city: ${city}, country: ${country}`);

    // Function to query GPT for name plausibility
    const queryGPT = async (content) => {
        try {
            const response = await fetch('https://api.openai.com/v1/chat/completions', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${apiKey}`
                },
                body: JSON.stringify({
                    model: "gpt-3.5-turbo",
                    messages: [
                        {
                            role: "system",
                            content: "You are an expert at determining the realism of a person's name in a given geographic location. Score the combination on a scale of 0 to 5 - only returning the number with no text"
                        },
                        {
                            role: "user",
                            content
                        }
                    ],
                    max_tokens: 100,
                })
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }

            const data = await response.json();
            const realismScore = parseInt(data.choices[0].message.content.trim(), 10);

            return isNaN(realismScore) ? 0 : realismScore;  // Ensure it's a valid number
        } catch (error) {
            console.error('Error fetching realism score:', error);
            return 0;  // Default to 0 in case of error
        }
    };

    try {
        // Step 1: Ask GPT for realism score from 0 to 5
        const query = `How realistic is the name "${firstName} ${lastName}" in the location "${location}"? Score it from 0 to 5.`;
        const realismScore = await queryGPT(query);

        // Step 2: Define percentages based on the realism score
        let threatPercentage;
        switch (realismScore) {
            case 5:
                threatPercentage = 0.20;  // 15% threats
                break;
            case 4:
                threatPercentage = 0.15;  // 10% threats
                break;
            case 3:
                threatPercentage = 0.10;  // 5% threats
                break;
            case 2:
                threatPercentage = 0.05;  // 3% threats
                break;
            case 1:
                threatPercentage = 0.0;  // 2% threats
                break;
            default:
                threatPercentage = 0;     // 0% threats for nonsensical names
        }

        // Step 3: Calculate the number of matches based on the threat percentage
        const totalSites = 208;
        const estimatedMatches = Math.floor(threatPercentage * totalSites);

        console.log('Realism Score:', realismScore);
        console.log('Estimated Matches:', estimatedMatches);
        console.log('Threat Percentage:', threatPercentage);

        // Return the probability as the number of threats found (matches / total sites)
        return Math.min(estimatedMatches / totalSites, 1);  // Ensure it doesn't exceed 1 (100%)
    } catch (error) {
        console.error('Error during match probability calculation:', error);
        return 0;  // Return 0 threats in case of error
    }
};
