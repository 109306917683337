import React, { useState, useEffect } from 'react';
import './FeatureToggles.css';
import ToggleSwitch from './ToggleSwitch';

const FeatureToggles = ({ apiUrl }) => {
    const [toggles, setToggles] = useState({
        multiFactorAuth: false,
        roleBasedAccess: false,
        monitoringVerification: false,
        dataLeakNotification: false
    });

    useEffect(() => {
        const fetchToggles = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch(apiUrl, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const data = await response.json();

                if (response.ok && data) {
                    console.log('Fetched toggles:', data);
                    setToggles({
                        multiFactorAuth: data.multi_factor_auth || false,
                        roleBasedAccess: data.role_based_access || false,
                        monitoringVerification: data.monitoring_verification || false,
                        dataLeakNotification: data.data_leak_notification || false
                    });
                } else {
                    console.error('Error fetching toggles:', data);
                    setToggles({
                        multiFactorAuth: false,
                        roleBasedAccess: false,
                        monitoringVerification: false,
                        dataLeakNotification: false
                    });
                }
            } catch (error) {
                console.error('Error fetching toggles:', error);
                setToggles({
                    multiFactorAuth: false,
                    roleBasedAccess: false,
                    monitoringVerification: false,
                    dataLeakNotification: false
                });
            }
        };

        fetchToggles();
    }, [apiUrl]);

    const handleToggleChange = async (name) => {
        const updatedToggles = { ...toggles, [name]: !toggles[name] };
        setToggles(updatedToggles);
    
        const token = localStorage.getItem('token');
        console.log('Sending toggle update:', updatedToggles);
    
        try {
            const response = await fetch(apiUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(updatedToggles)
            });
            const responseData = await response.json();
    
            if (response.ok && responseData) {
                console.log('Updated toggle successfully:', responseData);
                setToggles(responseData);
            } else {
                console.error('Error updating toggle:', responseData);
                // Rollback the toggle state if the update fails
                setToggles({ ...toggles, [name]: !toggles[name] });
            }
        } catch (error) {
            console.error('Error updating toggle:', error);
            // Rollback the toggle state if the update fails
            setToggles({ ...toggles, [name]: !toggles[name] });
        }
    };
    

    return (
        <div className="feature-toggles">
            <div className="toggle-item">
                <div className="toggle-header">
                    <span className="toggle-title">Multi-Factor Authentication</span>
                    <div className="toggle-switch-container">
                        <ToggleSwitch
                            id="multiFactorAuth"
                            isOn={toggles.multiFactorAuth}
                            handleToggle={() => handleToggleChange('multiFactorAuth')}
                        />
                    </div>
                </div>
                <p className="toggle-description">Enabled</p>
            </div>
            <div className="toggle-item">
                <div className="toggle-header">
                    <span className="toggle-title">Role-Based Access Control</span>
                    <div className="toggle-switch-container">
                        <ToggleSwitch
                            id="roleBasedAccess"
                            isOn={toggles.roleBasedAccess}
                            handleToggle={() => handleToggleChange('roleBasedAccess')}
                        />
                    </div>
                </div>
                <p className="toggle-description">Only available on family plan</p>
            </div>
            <div className="toggle-item">
                <div className="toggle-header">
                    <span className="toggle-title">Monitoring & Verification</span>
                    <div className="toggle-switch-container">
                        <ToggleSwitch
                            id="monitoringVerification"
                            isOn={toggles.monitoringVerification}
                            handleToggle={() => handleToggleChange('monitoringVerification')}
                        />
                    </div>
                </div>
                <p className="toggle-description">24/7 comprehensive monitoring</p>
            </div>
            <div className="toggle-item">
                <div className="toggle-header">
                    <span className="toggle-title">Data Leak Notification</span>
                    <div className="toggle-switch-container">
                        <ToggleSwitch
                            id="dataLeakNotification"
                            isOn={toggles.dataLeakNotification}
                            handleToggle={() => handleToggleChange('dataLeakNotification')}
                        />
                    </div>
                </div>
                <p className="toggle-description">Scan different databases for your leaked information</p>
            </div>
        </div>
    );
};

export default FeatureToggles;
