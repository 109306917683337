import React, { useState } from 'react';
import './DataLeakPage.css';
import MobileNavbar from '../components/MobileNavbar'; // Assuming MobileNavbar is imported
import { faEraser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Assuming you're using FontAwesome

const DataLeakPage = () => {
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [breaches, setBreaches] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [expandedBreach, setExpandedBreach] = useState(null);
    const [showMore, setShowMore] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setBreaches([]);
        setErrorMessage('');
        setExpandedBreach(null);
        setShowMore(false);

        try {
            const response = await fetch('https://dashboard-api-server.fly.dev/api/check-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            if (response.status === 200) {
                const data = await response.json();
                setBreaches(data);
            } else if (response.status === 404) {
                setErrorMessage('No breaches found for this email.');
            } else {
                setErrorMessage('Something went wrong. Please try again later.');
            }
        } catch (error) {
            setErrorMessage('Error fetching breach information.');
        } finally {
            setLoading(false);
        }
    };

    const handleNameSubmit = (e) => {
        e.preventDefault();
        window.location.href = `https://app.cleandata.me/location?first=${firstName}&last=${lastName}`;
    };

    const toggleDetails = (breach) => {
        setExpandedBreach(expandedBreach === breach ? null : breach);
    };

    const breachesToShow = showMore ? breaches : breaches.slice(0, 4);

    return (
        <div className="data-leak-page">
            {/* Include MobileNavbar */}
            <MobileNavbar />

            {/* Header Section */}
            <div className="data-leak-header">
                <h1>CleanData Email Leak Checker</h1>
                <p>Check if your email has been involved in any data breaches and protect your privacy.</p>
            </div>

            {/* Eraser Icon larger and moved below the description */}
            <div style={{ textAlign: 'center', margin: '20px 0' }}>
                <FontAwesomeIcon icon={faEraser} size="7x" className="eraser-icon" />
            </div>

            <form className="data-leak-form" onSubmit={handleSubmit}>
                <div className="input-group">
                    <input
                        type="email"
                        placeholder="Enter your email address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className="email-input"
                    />
                    <button type="submit" className="check-button" disabled={loading}>
                        {loading ? 'Checking...' : 'Check Now'}
                    </button>
                </div>
            </form>

            {errorMessage && <p className="error-message">{errorMessage}</p>}

            {breaches.length > 0 && (
                <>
                    <div className="breach-results">
                        <h2>Breach Results for {email}:</h2>
                        <ul className="breach-list">
                            {breachesToShow.map((breach) => (
                                <li key={breach.Name} className="breach-item">
                                    <div className="breach-summary">
                                        <h3>{breach.Title}</h3>
                                        <p><strong>Breach Date:</strong> {new Date(breach.BreachDate).toLocaleDateString()}</p>
                                        <p><strong>Leaked Data:</strong> {breach.DataClasses.join(', ')}</p>
                                        <button
                                            className="show-more-btn"
                                            onClick={() => toggleDetails(breach)}
                                        >
                                            <i className={`fas fa-chevron-${expandedBreach === breach ? 'up' : 'down'} arrow-icon`}></i>
                                        </button>
                                    </div>

                                    {expandedBreach === breach && (
                                        <div className="breach-details">
                                            <p><strong>Description:</strong> <span dangerouslySetInnerHTML={{ __html: breach.Description }} /></p>
                                        </div>
                                    )}
                                </li>
                            ))}
                        </ul>

                        {breaches.length > 4 && !showMore && (
                            <div className="show-more">
                                <button onClick={() => setShowMore(true)}>
                                    Show {breaches.length - 4} More Breaches <i className="fas fa-chevron-right"></i>
                                </button>
                            </div>
                        )}
                    </div>

                    {/* New Section only shown after breaches */}
                    <div className="data-brokers-check">
                        <p>Think that's bad? Let's check which data brokers are selling your information:</p>
                        <form className="name-form" onSubmit={handleNameSubmit}>
                            <div className="input-group">
                                <input
                                    type="text"
                                    placeholder="First Name"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    required
                                    className="name-input"
                                />
                                <input
                                    type="text"
                                    placeholder="Last Name"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    required
                                    className="name-input"
                                />
                                <button type="submit" className="check-button">
                                    Check Brokers
                                </button>
                            </div>
                        </form>
                    </div>
                </>
            )}

            <div className="breach-stats">
                <div className="breach-stat">
                    <h2>14.1 Billion</h2>
                    <p>Total Accounts Leaked</p>
                </div>
                <div className="breach-stat">
                    <h2>808</h2>
                    <p>Leaked Websites</p>
                </div>
                <div className="breach-stat">
                    <h2>115,796</h2>
                    <p>Pastes</p>
                </div>
                <div className="breach-stat">
                    <h2>228 Million</h2>
                    <p>Paste Accounts</p>
                </div>
            </div>

            <div className="additional-info">
                <h2>What to Do Next?</h2>
                <p>If your email has been exposed in a data breach, change your passwords, enable two-factor authentication, and secure your accounts to prevent further leaks.</p>
            </div>
        </div>
    );
};

export default DataLeakPage;
