import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaEraser } from 'react-icons/fa';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import './Navbar.css';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
        if (isOpen) setDropdownOpen(false);
    };

    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    // Handle click outside dropdown to close it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <header className="navbar">
            <div className="navbar-content">
                <div className="logo-container">
                    <FaEraser className="eraser-icon" />
                    <span className="logo-text">CleanData</span>
                    <span className="logo-subtitle">.me</span>
                </div>
                <button className="menu-toggle" onClick={toggleMenu}>
                    <span className="menu-icon">&#9776;</span>
                </button>
                <nav className={`nav-links ${isOpen ? 'open' : ''}`}>
                    <Link to="/">Home</Link>
                    <Link to="/about">About</Link>
                    <Link to="http://www.cleandata.me/blog">Blog</Link>
                    <Link to="http://wwww.cleandata.me/contact">Contact</Link>
                    <Link to="htttp://www.cleandata.me/pricing">Pricing</Link>
                    <div className="dropdown-container" ref={dropdownRef}>
                        <button className="dropdown-toggle" onClick={toggleDropdown}>
                            Free Tools {isDropdownOpen ? <FiChevronUp /> : <FiChevronDown />}
                        </button>
                        {isDropdownOpen && (
                            <ul className="dropdown">
                                <li>
                                    <Link to="/password-check">Password Checker</Link>
                                </li>
                                <li>
                                    <Link to="/file-scan">Virus Scanner</Link>
                                </li>
                                <li>
                                    <Link to="/location">Data Broker Check</Link>
                                </li>
                                <li>
                                    <Link to="/data-leak">Check Your Email</Link>
                                </li>
                            </ul>
                        )}
                    </div>
                    <Link to="/login" className="login-btn">Login</Link>
                </nav>
            </div>
        </header>
    );
};

export default Navbar;
