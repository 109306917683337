import React, { useState } from 'react';
import './PasswordCheckPage.css';
import { FaEye, FaEyeSlash, FaCopy, FaShieldAlt, FaLightbulb, FaVideo, FaInfoCircle, FaCheckCircle } from 'react-icons/fa';
import MobileNavbar from '../components/MobileNavbar';
import Navbar from '../components/Navbar';
import useWindowSize from '../hooks/useWindowSize';

const PasswordCheckPage = () => {
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');
    const [attackTypes, setAttackTypes] = useState([]);
    const [bruteForceTime, setBruteForceTime] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [generatedPassword, setGeneratedPassword] = useState('');
    const [generatedBruteForceTime, setGeneratedBruteForceTime] = useState('');
    const [showInfoCheck, setShowInfoCheck] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const { width } = useWindowSize();
    const [safetyScore, setSafetyScore] = useState(0);
    const [copySuccess, setCopySuccess] = useState('');
    const [infoCheckResult, setInfoCheckResult] = useState(null);

    const guessesPerSecond = 1e12; // 1 trillion guesses per second

    // Rounds large numbers to readable formats (weeks, years, etc.)
    const roundTime = (time) => {
        if (time < 1) {
            return `${(time * 60).toFixed(2)} minutes`;
        }
        if (time < 24) {
            return `${time.toFixed(2)} hours`;
        }
        const days = time / 24;
        if (days < 365) {
            return `${days.toFixed(2)} days`;
        }
        const years = days / 365;
        return `${years.toFixed(2)} years`;
    };

    const isCommonPassword = (pwd) => ['password', '123456', 'qwerty'].includes(pwd.toLowerCase());

    const estimateBruteForceTime = (pwd) => {
        const possibleCombinations = Math.pow(95, pwd.length); 
        const timeInSeconds = possibleCombinations / guessesPerSecond;
        const timeInHours = timeInSeconds / 3600;
        return roundTime(timeInHours);
    };

    const generateSuggestions = (pwd) => {
        const suggestions = [];
        if (pwd.length < 12) suggestions.push('Use at least 12 characters.');
        if (!/[A-Z]/.test(pwd)) suggestions.push('Include uppercase letters.');
        if (!/[a-z]/.test(pwd)) suggestions.push('Include lowercase letters.');
        if (!/[0-9]/.test(pwd)) suggestions.push('Include numbers.');
        if (!/[!@#$%^&*(),.?":{}|<>]/.test(pwd)) suggestions.push('Include special characters.');
        if (isCommonPassword(pwd)) suggestions.push('Avoid using common passwords.');
        return suggestions;
    };

    const generateStrongPassword = () => {
        const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~|}{[]:;?><,./-=";
        let pwd = "";
        for (let i = 0; i < 16; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            pwd += charset[randomIndex];
        }
        return pwd;
    };

    // Safety Score Calculation
    const calculateSafetyScore = (pwd) => {
        let score = 0;

        // Length Criteria (40 points)
        if (pwd.length >= 15) {
            score += 40;
        } else if (pwd.length >= 11) {
            score += 20;
        } else if (pwd.length >= 8) {
            score += 10;
        }

        // Character Variety (40 points)
        let varietyPoints = 0;
        if (/[A-Z]/.test(pwd)) varietyPoints += 10; // Uppercase
        if (/[a-z]/.test(pwd)) varietyPoints += 10; // Lowercase
        if (/[\d]/.test(pwd)) varietyPoints += 10; // Numbers
        if (/[!@#$%^&*(),.?":{}|<>]/.test(pwd)) varietyPoints += 10; // Special Characters
        score += varietyPoints;

        // Commonality (20 points)
        if (!isCommonPassword(pwd)) {
            score += 20;
        }

        // Ensure score does not exceed 100
        if (score > 100) score = 100;

        return score;
    };

    const handleCheckPassword = () => {
        setError('');
        setAttackTypes([]);
        setBruteForceTime('');
        setSuggestions([]);
        setShowInfoCheck(false);

        if (!password) {
            setError('Please enter a password.');
            return;
        }

        const attacks = isCommonPassword(password) ? ['Dictionary Attack', 'Brute Force Attack'] : ['Brute Force Attack'];
        const bruteTime = estimateBruteForceTime(password);
        const pwdSuggestions = generateSuggestions(password);
        const score = calculateSafetyScore(password);

        setAttackTypes(attacks);
        setBruteForceTime(bruteTime);
        setSuggestions(pwdSuggestions);
        setSafetyScore(score);
        setShowInfoCheck(true);
    };

    const handleGeneratePassword = () => {
        const strongPwd = generateStrongPassword();
        setGeneratedPassword(strongPwd);
        setGeneratedBruteForceTime(estimateBruteForceTime(strongPwd));
        const score = calculateSafetyScore(strongPwd);
        setSafetyScore(score);
        setShowInfoCheck(true);
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(generatedPassword)
            .then(() => {
                setCopySuccess('Password copied to clipboard!');
                setTimeout(() => setCopySuccess(''), 3000);
            })
            .catch(() => {
                setCopySuccess('Failed to copy password.');
                setTimeout(() => setCopySuccess(''), 3000);
            });
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        // Placeholder for actual API call
        // Simulating API response
        const mockApiResponse = {
            success: true,
            message: 'Your information was not found in any breach databases.'
        };
        // Simulate network delay
        setTimeout(() => {
            setInfoCheckResult(mockApiResponse);
        }, 1500);
    };

    return (
        <div className="password-check-page">
            {/* Navbar */}
            {width > 768 && <Navbar />}
            {width <= 768 && <MobileNavbar />}

            {/* Content Wrapper */}
            <div className="content-wrapper">
                {/* Header Section */}
                <div className="header-section card">
                    <h1><FaShieldAlt className="header-icon" /> Password Strength Checker</h1>
                    <p>Please enter a password to evaluate its strength. <strong>Do not enter your actual passwords</strong>; use similar words for testing purposes.</p>
                </div>

                {/* Password Check Form */}
                <div className="password-check-form card">
                    <div className="input-group">
                        <div className="password-input-wrapper">
                            <input
                                type={showPassword ? 'text' : 'password'}
                                className="password-input"
                                placeholder="Enter your password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                aria-label="Password input"
                            />
                            <button
                                className="toggle-visibility-button"
                                onClick={() => setShowPassword(!showPassword)}
                                aria-label={showPassword ? 'Hide password' : 'Show password'}
                            >
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </button>
                        </div>
                        <button className="check-button" onClick={handleCheckPassword}>Check Password</button>
                    </div>
                    {error && <div className="error-message">{error}</div>}
                </div>

                {/* Analysis Results */}
                {showInfoCheck && (
                    <div className="analysis-results card">
                        <h2><FaShieldAlt className="section-icon" /> Analysis Results</h2>
                        
                        {/* Safety Score Section */}
                        <div className="safety-score-section">
                            <div className="score-label">
                                <span>Safety Score: {safetyScore}%</span>
                                <span className="score-icon">
                                    {safetyScore < 50 && <FaInfoCircle title="Weak Password" />}
                                    {safetyScore >= 50 && safetyScore < 75 && <FaInfoCircle title="Moderate Password" />}
                                    {safetyScore >= 75 && <FaInfoCircle title="Strong Password" />}
                                </span>
                            </div>
                            {/* Glowing Icon */}
                            <div className="glowing-icon">
                                <FaShieldAlt
                                    style={{
                                        color: safetyScore < 50 ? '#ff4d4d' : safetyScore < 75 ? '#ffa500' : '#66ff66'
                                    }}
                                />
                            </div>
                            <div className="strength-label">
                                {safetyScore < 50 && 'Weak'}
                                {safetyScore >= 50 && safetyScore < 75 && 'Moderate'}
                                {safetyScore >= 75 && 'Strong'}
                            </div>
                        </div>

                        {/* Possible Attacks */}
                        <div className="result-box">
                            <h3><FaInfoCircle className="subsection-icon" /> Possible Attacks:</h3>
                            <ul className="result-list">
                                {attackTypes.map((attack, index) => (
                                    <li key={index} className={`attack-badge ${attack.toLowerCase().replace(' ', '-')}`}>
                                        {attack}
                                        <FaInfoCircle className="tooltip-icon" title={attack === 'Dictionary Attack' ? 'An attack using a list of common words.' : 'An attack trying all possible combinations.'} />
                                    </li>
                                ))}
                            </ul>
                            <p><strong>Estimated time to crack via brute force:</strong> <span className="crack-time">{bruteForceTime}</span></p>
                        </div>

                        {/* Suggestions */}
                        {suggestions.length > 0 && (
                            <div className="result-box">
                                <h3><FaLightbulb className="subsection-icon" /> Suggestions to Improve Your Password:</h3>
                                <ul className="result-list">
                                    {suggestions.map((suggestion, index) => (
                                        <li key={index}>{suggestion}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                )}

                {/* Password Generation */}
                <div className="password-generation card">
                    <h2><FaShieldAlt className="section-icon" /> Generate a Secure Password</h2>
                    <button className="generate-password-button" onClick={handleGeneratePassword}>Generate Strong Password</button>
                    {generatedPassword && (
                        <div className="generated-password-box">
                            <p className="generated-password">{generatedPassword}</p>
                            <button className="copy-button" onClick={handleCopy} aria-label="Copy password to clipboard">
                                <FaCopy />
                            </button>
                        </div>
                    )}
                    {copySuccess && <div className="copy-success">{copySuccess}</div>}
                    {generatedBruteForceTime && (
                        <div className="crack-time-display">
                            <p><strong>Estimated time to crack:</strong> <span className="crack-time">{generatedBruteForceTime}</span></p>
                        </div>
                    )}
                </div>

                {/* Additional Password Security Tips */}
                <div className="password-tips-section card">
                    <h2><FaLightbulb className="section-icon" /> Why Strong Passwords Matter</h2>
                    <div className="tips-list">
                        <div className="tip-item">
                            <FaCheckCircle className="tip-icon" />
                            <p>Passwords should be at least 12-16 characters long to resist brute force attacks.</p>
                        </div>
                        <div className="tip-item">
                            <FaCheckCircle className="tip-icon" />
                            <p>Never reuse the same password across different sites and apps.</p>
                        </div>
                        <div className="tip-item">
                            <FaCheckCircle className="tip-icon" />
                            <p>Avoid common phrases or easily guessable patterns, like "password123".</p>
                        </div>
                        <div className="tip-item">
                            <FaCheckCircle className="tip-icon" />
                            <p>Consider using two-factor authentication (2FA) for extra security.</p>
                        </div>
                        <div className="tip-item">
                            <FaCheckCircle className="tip-icon" />
                            <p>Use password managers to store and generate strong passwords easily.</p>
                        </div>
                    </div>
                </div>

                {/* Video Section */}
                <div className="password-manager-section card">
                    <h2><FaVideo className="section-icon" /> Why Not Use a Password Manager?</h2>
                    <p>While generating strong passwords is essential, managing them can be challenging. Password managers help you store, generate, and retrieve complex passwords securely.</p>
                    <div className="video-container">
                        <iframe
                            src="https://www.youtube.com/embed/JSQNC3BWGh8?si=V08DZrL0GmqSt9Km"
                            title="Password Manager Video"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div>
                    <a className="button-link" href="https://1password.com/" target="_blank" rel="noopener noreferrer">Get 1Password</a>
                </div>

                {/* Conditional Info Check Section */}
                {showInfoCheck && (
                    <div className="info-check-section card">
                        <h2><FaInfoCircle className="section-icon" /> Check If People Are Selling Your Information</h2>
                        <p>Enter your first and last name to see if your information is being sold or compromised.</p>
                        <form onSubmit={handleFormSubmit}>
                            <input
                                type="text"
                                placeholder="First Name"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                required
                                aria-label="First Name"
                            />
                            <input
                                type="text"
                                placeholder="Last Name"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                required
                                aria-label="Last Name"
                            />
                            <button type="submit" className="check-info-button">Check Now</button>
                        </form>
                        {infoCheckResult && (
                            <div className={`info-check-result ${infoCheckResult.success ? 'success' : 'error'}`}>
                                {infoCheckResult.message}
                            </div>
                        )}
                    </div>
                )}
            </div> {/* End of content-wrapper */}
        </div>
    );

};

export default PasswordCheckPage;
