import React, { useState } from 'react';
import { FaUpload, FaExclamationTriangle, FaFileAlt, FaBug, FaCheckCircle, FaShieldAlt, FaUserCheck, FaClock } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import MobileNavbar from '../components/MobileNavbar';
import Navbar from '../components/Navbar';
import useWindowSize from '../hooks/useWindowSize';
import './FileScanPage.css';

const FileScanPage = () => {
  const [fileUploaded, setFileUploaded] = useState(false);
  const { width } = useWindowSize();

  // Mock statistics
  const stats = {
    documentsUploaded: 1203,
    virusesFound: 78,
    scansCompleted: 950,
  };

  // Mock testimonials
  const testimonials = [
    {
      name: 'John Doe',
      feedback: 'CleanData’s File Scanner is fast and reliable. It gave me peace of mind knowing my files are safe.',
      avatar: 'https://randomuser.me/api/portraits/men/32.jpg',
    },
    {
      name: 'Jane Smith',
      feedback: 'Excellent service! The scanning process is thorough, and the interface is user-friendly.',
      avatar: 'https://randomuser.me/api/portraits/women/44.jpg',
    },
    {
      name: 'Alice Johnson',
      feedback: 'I trust CleanData with all my file security needs. Highly recommended!',
      avatar: 'https://randomuser.me/api/portraits/women/68.jpg',
    },
  ];

  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      setFileUploaded(true);
      // Simulate scanning progress
      setTimeout(() => {
        setFileUploaded(false); // Reset after simulation
      }, 3000);
    } else {
      setFileUploaded(false);
    }
  };

  return (
    <div className="file-scan-page">
      {width <= 768 ? <MobileNavbar /> : <Navbar />}

      <div className="scanner-wrapper">
        <h1 className="page-title">CleanData’s File Scanner</h1>
        <p className="page-description">
          Securely scan your files for viruses, malware, and other potential threats. CleanData’s File Scanner helps you protect your digital assets by identifying any vulnerabilities in your files.
        </p>

        <div className="file-scan-content">
          {/* Statistics Section */}
          <section className="stats-section">
            <div className="stat-item">
              <FaFileAlt size={40} color="#00ff85" />
              <p className="stat-number">{stats.documentsUploaded}</p>
              <p className="stat-label">Documents Uploaded</p>
            </div>
            <div className="stat-item">
              <FaBug size={40} color="#ff4d4d" />
              <p className="stat-number">{stats.virusesFound}</p>
              <p className="stat-label">Viruses Found</p>
            </div>
            <div className="stat-item">
              <FaCheckCircle size={40} color="#00cc6a" />
              <p className="stat-number">{stats.scansCompleted}</p>
              <p className="stat-label">Scans Completed</p>
            </div>
          </section>

          {/* File Upload Section */}
          <section className="file-scan-container">
            <div className="upload-section">
              <FaUpload size={60} color="#00ff85" />
              <div className="file-input-wrapper">
                <input
                  type="file"
                  id="file-upload"
                  className="file-input"
                  onChange={handleFileChange}
                  aria-label="File upload"
                />
                <label htmlFor="file-upload" className="upload-label">
                  Drag and drop a file here, or click to select a file
                </label>
              </div>
            </div>

            <div className="progress-section">
              <div className="progress-bar">
                <div className="progress-fill" style={{ width: fileUploaded ? '70%' : '0%' }}></div>
              </div>
              <p className="progress-text">
                {fileUploaded ? 'Scanning in progress...' : 'Please upload your file to check'}
              </p>
            </div>

            <div className="unavailable-notice">
              <FaExclamationTriangle size={30} color="#ff4d4d" />
              <p className="notice-text">
                This feature is currently unavailable. Please check back later.
              </p>
            </div>

            <Link to="/" className="home-link">
              Return to Home
            </Link>
          </section>
        </div>

        {/* Why Choose Us Section */}
        <section className="why-choose-us">
          <h2>Why Choose CleanData?</h2>
          <div className="features">
            <div className="feature-item">
              <FaShieldAlt size={30} color="#00ff85" />
              <h3>Top-Notch Security</h3>
              <p>Your files are scanned with the latest security protocols to ensure maximum protection.</p>
            </div>
            <div className="feature-item">
              <FaUserCheck size={30} color="#00ff85" />
              <h3>User-Friendly Interface</h3>
              <p>Our intuitive design makes it easy for anyone to scan their files effortlessly.</p>
            </div>
            <div className="feature-item">
              <FaClock size={30} color="#00ff85" />
              <h3>Fast Scanning</h3>
              <p>Receive scan results in minutes, not hours, so you can stay productive.</p>
            </div>
          </div>
        </section>

        {/* User Testimonials Section */}
        <section className="testimonials-section">
          <h2>What Our Users Say</h2>
          <div className="testimonials">
            {testimonials.map((testimonial, index) => (
              <div className="testimonial-item" key={index}>
                <img src={testimonial.avatar} alt={`${testimonial.name}'s avatar`} className="testimonial-avatar" />
                <p className="testimonial-feedback">"{testimonial.feedback}"</p>
                <p className="testimonial-name">- {testimonial.name}</p>
              </div>
            ))}
          </div>
        </section>

        {/* Security Features Section */}
        <section className="security-features">
          <h2>Our Security Features</h2>
          <div className="security-items">
            <div className="security-item">
              <FaShieldAlt size={30} color="#00ff85" />
              <h3>Encrypted Scanning</h3>
              <p>All scans are performed using end-to-end encryption to protect your data.</p>
            </div>
            <div className="security-item">
              <FaUserCheck size={30} color="#00ff85" />
              <h3>No Data Storage</h3>
              <p>We do not store any of your uploaded files. Your privacy is our priority.</p>
            </div>
            <div className="security-item">
              <FaClock size={30} color="#00ff85" />
              <h3>24/7 Support</h3>
              <p>Our support team is available around the clock to assist you with any issues.</p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default FileScanPage;
