export const getUser = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
        console.warn('No token found in localStorage');
        return null;
    }

    try {
        console.log('Fetching user data with token:', token);

        const response = await fetch('https://cleandata.fly.dev/api/user-data', {  // Ensure this URL matches your server
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (!response.ok) {
            const errorText = await response.text();
            console.error('Failed to fetch user:', errorText);
            throw new Error(`Failed to fetch user: ${response.status} - ${errorText}`);
        }

        const userData = await response.json();
        console.log('User data fetched successfully:', userData);
        return userData;
    } catch (error) {
        console.error('Error fetching user:', error);
        return null;
    }
};

export const login = async (username, password) => {
    console.log('Attempting login with username:', username);

    try {
        const response = await fetch('https://cleandata.fly.dev/api/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username, password })
        });

        if (!response.ok) {
            const errorText = await response.text();
            console.error('Login failed:', errorText);
            throw new Error(`Login failed: ${response.status} - ${errorText}`);
        }

        const loginData = await response.json();
        console.log('Login successful:', loginData);

        if (loginData && loginData.token) {
            localStorage.setItem('token', loginData.token);
            console.log('Token saved to localStorage:', loginData.token);
            return loginData;
        } else {
            console.error('Login response did not contain a token');
            return null;
        }
    } catch (error) {
        console.error('Error during login:', error);
        return null;
    }
};

export const logout = () => {
    console.log('Logging out, removing token from localStorage');
    localStorage.removeItem('token');
};
